<template>
  <div class="eh-participant-component">
    <!-- új űrlap gomb -->
    <b-container fluid="true">
      <b-row>
        <b-col>
          <button @click="showNewFormIntance = true" class="eh-action-button">
            {{ $t("healthProfessional.patientList.formsTab.newForm") }}
          </button>
        </b-col>
      </b-row>
    </b-container>
    <!-- Szűrők -->
    <div class="eh-flex-container">
      <fp-input
        class="eh-margin-right eh-fix-filter-input-width"
        :label="$t('base.basic.filter')"
        @change="filterFormList"
        v-model="filter"
      />
      <fp-select
        class="eh-margin-right eh-fix-filter-input-width"
        :label="$t('healthProfessional.patientList.formsTab.state')"
        :items="Object.values($enums.FormStatus)"
        valueKey="Value"
        @change="filterFormList"
        v-model="stateFilter"
      >
        <template #element="element">
          {{ $t(element.Text) }}
        </template>
      </fp-select>
      <fp-date-range-button
        :label="$t('healthProfessional.patientList.formsTab.filledDate')"
        v-model="filledDateFilter"
        @change="filterFormList"
      />
    </div>
    <!-- Kiosztott űrlapok -->
    <fp-table-list
      :bordered="true"
      :hasPagination="true"
      :items="filteredFormInstanceHeaderList"
      :fields="formInstanceHeaderListFields"
      sortBy="FilledDate"
    >
      <template #Title="row">
        {{ getTextByLanguage(row.item.Title, row.item.Languages) }}
      </template>
      <template #FilledDate="row">
        {{ $convertDateToString(row.item.FilledDate, "YYYY-MM-DD HH:mm") }}
      </template>
      <template #Status="row">
        <!-- {{ getEnumText(row.item.Status, "FormStatus") }} -->
        {{ $t(formStatuses[row.item.Status].Text) }}
      </template>
      <template #Buttons="row">
        <b-icon
          style="cursor: pointer"
          v-if="row.detailsShowing"
          class="h3"
          icon="x-circle-fill"
          @click="$set(row.item, '_showDetails', false)"
        ></b-icon>
        <b-icon
          style="cursor: pointer"
          class="h3"
          v-else
          :icon="
            row.item.Status == $enums.FormStatus.Filled.Value
              ? 'eye-fill'
              : 'pencil-fill'
          "
          @click="$set(row.item, '_showDetails', true)"
        ></b-icon>
      </template>
      <template #row-details="row">
        <b-card v-if="row.item">
          <fp-form-schema-builder
            :formInstanceId="row.item.FormInstanceId"
            :disabled="row.item.Status == $enums.FormStatus.Filled.Value"
            @closeForm="closeDetails(row.item)"
          />
        </b-card>
        <div v-else>
          <b-spinner></b-spinner>
        </div>
      </template>
    </fp-table-list>
    <!-- Kiosztható űrlapok modal -->
    <fp-form-modal
      size="lg"
      :title="$t('healthProfessional.patientList.formsTab.newForm')"
      v-model="showNewFormIntance"
    >
      <template #content>
        <!-- Kiosztható űrlapok lista -->
        <fp-table-list
          :items="formSchemaHeaderList"
          :fields="formSchemaHeaderListFields"
        >
          <template #Buttons="row">
            <b-icon
              class="h4"
              style="cursor: pointer"
              icon="eye-fill"
              v-b-tooltip.hover.left="$t('base.basic.view')"
              @click="$set(row.item, 'ShowFormSchema', true)"
            />
            <b-iconstack
              @click="assignForm(row.item.FormSchemaId)"
              class="h3"
              style="cursor: pointer"
              v-b-tooltip.hover.left="
                $t('healthProfessional.patientList.formsTab.assignment')
              "
            >
              <b-icon stacked icon="file-fill"></b-icon>
              <b-icon
                stacked
                icon="arrow-up-right"
                style="color: white"
                scale="0.6"
              ></b-icon>
            </b-iconstack>
            <fp-form-modal size="xl" v-model="row.item.ShowFormSchema">
              <template #content>
                <!-- TODO importálás vagy plugin használata -->
                <fp-form-schema-builder
                  :formSchemaId="row.item.FormSchemaId"
                  :disabled="true"
                />
              </template>
            </fp-form-modal>
          </template>
          <template #Title="row">
            {{ getTextByLanguage(row.item.Title, row.item.Languages) }}
          </template>
          <template #Description="row">
            {{ getTextByLanguage(row.item.Description, row.item.Languages) }}
          </template>
        </fp-table-list>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
import moment from "moment";
import { FormLogic } from "../../../Logic/Backend/form";
export default {
  name: "Forms",
  data() {
    return {
      formSchemaHeaderList: [],
      formSchemaHeaderListFields: [
        {
          key: "Title",
          label: this.$t("healthProfessional.patientList.formsTab.title"),
        },
        {
          key: "Description",
          label: this.$t("healthProfessional.patientList.formsTab.description"),
        },
        { key: "Buttons", label: "" },
      ],
      formInstanceHeaderList: [],
      filteredFormInstanceHeaderList: null,
      formInstanceHeaderListFields: [
        {
          key: "Title",
          label: this.$t("healthProfessional.patientList.formsTab.title"),
        },
        {
          key: "FilledDate",
          label: this.$t("healthProfessional.patientList.formsTab.endDate"),
          sortable: true,
        },
        {
          key: "Status",
          label: this.$t("healthProfessional.patientList.formsTab.state"),
        },
        {
          key: "Buttons",
          label: this.$t("healthProfessional.patientList.formsTab.operations"),
        },
      ],
      filter: null,
      stateFilter: null,
      filledDateFilter: null,
      showNewFormIntance: false,
      formStatuses: this.$enums.FormStatus,
    };
  },
  props: {
    participant: Object,
    project: Object,
  },
  watch: {
    //participant prop változás figyelő
    async participant() {
      //kérdőívek flista frissítés
      await this.getFormsInstanceHeaderList();
    },
  },
  methods: {
    getTextByLanguage(textObject, languages) {
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    filterFormList() {
      this.filteredFormInstanceHeaderList = this.formInstanceHeaderList;
      if (this.filter) {
        this.filteredFormInstanceHeaderList = this.$filterList(
          this.filter,
          this.formInstanceHeaderList,
          ["Title"]
        );
      }
      if (this.stateFilter) {
        this.filteredFormInstanceHeaderList = this.filteredFormInstanceHeaderList.filter(
          (task) => task.Status == this.stateFilter
        );
      }
      if (this.filledDateFilter) {
        this.filteredFormInstanceHeaderList = this.filteredFormInstanceHeaderList.filter(
          (task) => {
            if (this.filledDateFilter.from && this.filledDateFilter.to) {
              return moment(task.FilledDate).isBetween(
                this.filledDateFilter.from,
                this.filledDateFilter.to,
                undefined,
                "[]"
              );
            } else if (this.filledDateFilter.from) {
              return moment(task.FilledDate).isSameOrAfter(
                moment(this.filledDateFilter.from)
              );
            } else if (this.filledDateFilter.to) {
              return moment(task.FilledDate).isSameOrBefore(
                moment(this.filledDateFilter.to)
              );
            }
            return true;
          }
        );
      }
    },
    async closeDetails(rowItem) {
      this.$set(rowItem, "_showDetails", false),
        await this.getFormsInstanceHeaderList();
    },
    //kérdőív lista kérés
    async getFormsInstanceHeaderList() {
      //kérés indítása
      const formInstanceListResponse = await FormLogic.getFormInstanceHeader({
        SubjectId: this.participant.UserId,
        FormCategory: "Form",
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      });
      //sikeres kérés?
      if (!formInstanceListResponse.Code) {
        //lista frissítése (és ideiglenesen szűrés kiválasztott beteg id alapján)
        this.formInstanceHeaderList = this.filteredFormInstanceHeaderList = formInstanceListResponse;
      } else {
        this.$bvToast.toast(formInstanceListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t(
              "healthProfessional.patientList.formsTab.errorFormName"
            ),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getFormSchemaList() {
      //kérés indítása
      const formSchemaHeaderListResponse = await FormLogic.getFormSchemaHeader({
        FormCategory: "Form",
        Status: "Active",
      });
      //sikeres kérés?
      if (!formSchemaHeaderListResponse.Code) {
        const schemaList = formSchemaHeaderListResponse;
        this.formSchemaHeaderList = schemaList.filter((schema) =>
          this.project.Items.some(
            (item) =>
              item.EntityTypeName ==
                this.$enums.ProjectItemsEntityTypeName.FormSchema &&
              item.EntityId == schema.FormSchemaId
          )
        );
      } else {
        this.$bvToast.toast(formSchemaHeaderListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t(
              "healthProfessional.patientList.formsTab.errorAssignName"
            ),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async assignForm(formSchemaId) {
      const temp = {
        FormSchemaId: formSchemaId,
        FormElements: [{ SubjectId: this.participant.UserId }],
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      };
      const setFormToUser = await FormLogic.createFormInstance(temp);
      if (!setFormToUser.Code) {
        await this.getFormsInstanceHeaderList();
        this.showNewFormIntance = false;
        this.$bvToast.toast(
          this.$t("requestResponse.form.successCreateInstance"),
          {
            title: this.$t("base.basic.add"),
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(setFormToUser.Message, {
          title: this.$t("requestResponse.form.errorCreateInstance"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //betöltéskor egyszer lefut
  async beforeMount() {
    //kérdőívek lista betöltése
    await this.getFormsInstanceHeaderList();
    await this.getFormSchemaList();
  },
};
</script>
<style scoped>
.filter-operation {
  margin-right: 10px;
}
.date-picker-calendar-quest {
  position: absolute;
  top: 40px;
  right: -100px;
  background: white;
  border: solid 3px #627183;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
}
</style>
